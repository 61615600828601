import { getStudentAgeObject } from '@/components/utilities/utilities';
import WeightTable from '@/static/data/bmi-weight.json';
import HeightTable from '@/static/data/bmi-height.json';

const weightToMessage = (weight, student) => {
  const match = WeightTable.find(row => {
    const age = getStudentAgeObject(student.dateOfBirth);
    const ageMatch = row.year == age.year && row.month == age.month;
    const rangeMatch = weight >= row.min && weight <= row.max;
    return ageMatch && rangeMatch;
  });

  if (!match) return 'ไม่ระบุ';
  if (!weight || weight == 0) return 'ไม่ระบุ';

  const { standard } = match;

  switch (standard) {
    case 'too low':
      return 'ผอม';
    case 'too high':
      return 'อ้วน';
    case 'low':
      return 'ค่อนข้างผอม';
    case 'high':
      return 'ค่อนข้างอ้วน';
    case 'medium':
      return 'มีน้ำหนักตามเกณฑ์';
    default:
      return 'ไม่ระบุ';
  }
};

const heightToMessage = (height, student) => {
  const match = HeightTable.find(row => {
    const age = getStudentAgeObject(student.dateOfBirth);
    const ageMatch = row.year == age.year && row.month == age.month;
    const rangeMatch = height >= row.min && height <= row.max;
    return ageMatch && rangeMatch;
  });

  if (!match) return 'ไม่ระบุ';
  if (!height || height == 0) return 'ไม่ระบุ';

  const { standard } = match;

  switch (standard) {
    case 'too low':
      return 'เตี้ย';
    case 'too high':
      return 'สูง';
    case 'low':
      return 'ค่อนข้างเตี้ย';
    case 'high':
      return 'ค่อนข้างสูง';
    case 'medium':
      return 'มีส่วนสูงตามเกณฑ์';
    default:
      return 'ไม่ระบุ';
  }
};

const weightToScore = (weight, student) => {
  const match = WeightTable.find(row => {
    const age = getStudentAgeObject(student.dateOfBirth);
    const ageMatch = row.year == age.year && row.month == age.month;
    const rangeMatch = weight >= row.min && weight <= row.max;
    return ageMatch && rangeMatch;
  });

  if (!match) return null;

  const { standard } = match;

  if (standard.includes('too')) {
    return 1;
  } else if (standard == 'high' || standard == 'low') {
    return 2;
  } else if (standard == 'medium') {
    return 3;
  }

  return null;
};

const scaleToAlertType = (scale, student, type) => {
  const table = type == 'weight' ? WeightTable : HeightTable;
  const match = table.find(row => {
    const age = getStudentAgeObject(student.dateOfBirth);
    const ageMatch = row.year == age.year && row.month == age.month;
    const rangeMatch = scale >= row.min && scale <= row.max;
    return ageMatch && rangeMatch;
  });

  if (!match) return 'info';
  if (!scale || scale == 0) return 'info';

  const { standard } = match;

  if (standard.includes('too')) {
    return 'error';
  } else if (standard == 'high' || standard == 'low') {
    return 'warning';
  } else if (standard == 'medium') {
    return 'success';
  }

  return 'info';
};

const heightToScore = (height, student) => {
  const match = HeightTable.find(row => {
    const age = getStudentAgeObject(student.dateOfBirth);
    const ageMatch = row.year == age.year && row.month == age.month;
    const rangeMatch = height >= row.min && height <= row.max;
    return ageMatch && rangeMatch;
  });

  if (!match) return null;

  const { standard } = match;

  if (standard.includes('too')) {
    return 1;
  } else if (standard == 'high' || standard == 'low') {
    return 2;
  } else if (standard == 'medium') {
    return 3;
  }

  return null;
};

export { heightToScore, weightToScore, scaleToAlertType, heightToMessage, weightToMessage };
