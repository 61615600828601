<template>
  <div class="full-width-table">
    <sdCards title="สรุปพัฒนาการด้านร่างกาย">
      <div class="table-bordered table-responsive">
        <a-table :dataSource="dataSource" :columns="columns" :pagination="false" />
      </div>
    </sdCards>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { weightToMessage, heightToMessage } from '@/utility/bodymassCalculator';

// const columns = [
//   {
//     title: 'เกณฑ์น้ำหนัก',
//     dataIndex: 'w',
//     key: 'w',
//   },
//   {
//     title: 'จำนวน(คน)',
//     align: 'center',
//     dataIndex: 'w_amount',
//     key: 'w_amount',
//   },
//   {
//     title: 'เกณฑ์ส่วนสูง',
//     dataIndex: 'h',
//     key: 'h',
//   },
//   {
//     title: 'จำนวน(คน)',
//     align: 'center',
//     dataIndex: 'h_amount',
//     key: 'h_amount',
//   },
// ];

const columns = [
  {
    title: 'เกณฑ์น้ำหนัก',
    dataIndex: 'weightStandard',
    key: 'weightStandard',
  },
  {
    title: 'จำนวน(คน)',
    align: 'center',
    dataIndex: 'weightAmount',
    key: 'weightAmount',
  },
  {
    title: 'เกณฑ์ส่วนสูง',
    dataIndex: 'heightStandard',
    key: 'heightStandard',
  },
  {
    title: 'จำนวน(คน)',
    align: 'center',
    dataIndex: 'heightAmount',
    key: 'heightAmount',
  },
];

export default {
  name: 'BodymassAndAttendancePage',
  props: { classroomId: Number, schoolYearId: Number },
  setup(props) {
    const { state, getters, dispatch } = useStore();
    const students = computed(() => getters.getStudentsByClassroom(props.classroomId));
    const bodymassRecords = computed(() => state.bodymass.currentRecords);
    const schoolYear = computed(() => state.schoolYear.data.find(schoolYear => schoolYear.id == props.schoolYearId));

    onMounted(() => {
      dispatch('getClassroomBodymassRecords', {
        schoolYearId: schoolYear.value.id,
        classroomId: props.classroomId,
      });
    });

    const recentRecords = computed(() => {
      if (!bodymassRecords.value) return null;
      // 1. group record by `studentId`
      const groupObj = bodymassRecords.value.reduce((final, rec) => {
        if (!final[rec.studentId]) {
          final[rec.studentId] = [];
        }
        final[rec.studentId].push(rec);
        return final;
      }, {});

      // 2. get only value of group object
      const groupedRecords = Object.values(groupObj);

      // 3. sort inside group of records and get the last one
      let recentRecords = groupedRecords.map(group => {
        const sorted = group.sort((a, b) => {
          if (a.schoolTermId > b.schoolTermId) return -1;
          if (a.schoolTermId < b.schoolTermId) return 1;
          if (a.evaluateNo > b.evaluateNo) return -1;
          if (a.evaluateNo < b.evaluateNo) return 1;
        });
        return sorted[0];
      });

      // 4. assign student object to record
      recentRecords = recentRecords.map(rec => {
        const student = students.value.find(student => student.id == rec.studentId);
        return { ...rec, student };
      });

      // 5. filter no student obj records
      recentRecords = recentRecords.filter(record => record.student);

      return recentRecords;
    });

    const heightSource = computed(() => {
      if (!recentRecords.value) return null;

      // got some message
      const records = recentRecords.value.map(record => {
        const height = Number(record.height);
        const { student } = record;
        const message = heightToMessage(height, student);
        return {
          ...record,
          message,
        };
      });

      // reduce for height
      const result = records.reduce(
        (final, record) => {
          const key = record.message;
          final[key] = final[key] + 1;
          return final;
        },
        {
          เตี้ย: 0,
          สูง: 0,
          ค่อนข้างเตี้ย: 0,
          ค่อนข้างสูง: 0,
          มีส่วนสูงตามเกณฑ์: 0,
          ไม่ระบุ: 0,
        },
      );

      return result;
    });

    const weightSource = computed(() => {
      if (!recentRecords.value) return null;

      // got some message
      const records = recentRecords.value.map(record => {
        const weight = Number(record.weight);
        const { student } = record;
        const message = weightToMessage(weight, student);
        return {
          ...record,
          message,
        };
      });

      // reduce for weight
      const result = records.reduce(
        (final, record) => {
          const key = record.message;
          final[key] = final[key] + 1;
          return final;
        },
        {
          ผอม: 0,
          อ้วน: 0,
          ค่อนข้างผอม: 0,
          ค่อนข้างอ้วน: 0,
          มีน้ำหนักตามเกณฑ์: 0,
          ไม่ระบุ: 0,
        },
      );

      return result;
    });

    const dataSource = computed(() => {
      if (!recentRecords.value) return null;

      const heightKeys = Object.keys(heightSource.value);
      const weightKeys = Object.keys(weightSource.value);

      const data = heightKeys.map((_, index) => {
        const weightStandard = weightKeys[index];
        const heightStandard = heightKeys[index];
        const weightAmount = weightSource.value[weightStandard];
        const heightAmount = heightSource.value[heightStandard];

        return {
          key: index,
          weightStandard,
          weightAmount,
          heightStandard,
          heightAmount,
        };
      });

      return data;
    });

    // const bodymassDataSource = computed(() => {
    //   if (!bodymassRecords.value) return [];

    //   let weightSum = {
    //     ผอม: 0,
    //     อ้วน: 0,
    //     ค่อนข้างผอม: 0,
    //     ค่อนข้างอ้วน: 0,
    //     มีน้ำหนักตามเกณฑ์: 0,
    //     ไม่ระบุ: 0,
    //   };

    //   let heightSum = {
    //     เตี้ย: 0,
    //     สูง: 0,
    //     ค่อนข้างเตี้ย: 0,
    //     ค่อนข้างสูง: 0,
    //     มีส่วนสูงตามเกณฑ์: 0,
    //     ไม่ระบุ: 0,
    //   };

    //   students.value.forEach(student => {
    //     const records = bodymassRecords.value.filter(record => record.studentId == student.studentId);
    //     if (records.length == 0) {
    //       weightSum[`ไม่ระบุ`] = students.value.length;
    //       heightSum[`ไม่ระบุ`] = students.value.length;
    //       return [weightSum, heightSum];
    //     }
    //     let key = 'ไม่ระบุ';
    //     let value = 0;
    //     if (getWeightRecord(term2.value.id, 2, records)) {
    //       value = getWeightRecord(term2.value.id, 2, records);
    //     } else if (getWeightRecord(term2.value.id, 1, records)) {
    //       value = getWeightRecord(term2.value.id, 1, records);
    //     } else if (getWeightRecord(term1.value.id, 2, records)) {
    //       value = getWeightRecord(term1.value.id, 2, records);
    //     } else if (getWeightRecord(term1.value.id, 1, records)) {
    //       value = getWeightRecord(term1.value.id, 1, records);
    //     }
    //     key = weightToMessage(value, student);
    //     weightSum[`${key}`] = weightSum[`${key}`] + 1;

    //     key = 'ไม่ระบุ';
    //     value = 0;
    //     if (getHeightRecord(term2.value.id, 2, records)) {
    //       value = getHeightRecord(term2.value.id, 2, records);
    //     } else if (getHeightRecord(term2.value.id, 1, records)) {
    //       value = getHeightRecord(term2.value.id, 1, records);
    //     } else if (getHeightRecord(term1.value.id, 2, records)) {
    //       value = getHeightRecord(term1.value.id, 2, records);
    //     } else if (getHeightRecord(term1.value.id, 1, records)) {
    //       value = getHeightRecord(term1.value.id, 1, records);
    //     }

    //     key = heightToMessage(value, student);
    //     heightSum[`${key}`] = heightSum[`${key}`] + 1;
    //   });

    //   const types = [
    //     { w: 'มีน้ำหนักตามเกณฑ์', h: 'มีส่วนสูงตามเกณฑ์' },
    //     { w: 'อ้วน', h: 'สูง' },
    //     { w: 'ค่อนข้างอ้วน', h: 'ค่อนข้างสูง' },
    //     { w: 'ผอม', h: 'เตี้ย' },
    //     { w: 'ค่อนข้างผอม', h: 'ค่อนข้างเตี้ย' },
    //     { w: 'ไม่ระบุ', h: 'ไม่ระบุ' },
    //   ];

    //   const dataSource = types.map(type => {
    //     return { w: type.w, w_amount: weightSum[`${type.w}`], h: type.h, h_amount: heightSum[`${type.h}`] };
    //   });
    //   return dataSource;
    // });

    return {
      columns,
      dataSource,
    };
  },
};
</script>
