<template>
  <CardToolbox>
    <sdBreadcrumb :routes="routes" />
    <sdPageHeader>
      <template #title>
        {{ classroomInfo.title }}
      </template>
      <template #subTitle>
        {{ getGradeLevelTitle(gradeLevel) }}
      </template>
      <template #buttons> </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="[15, 15]">
      <a-col :md="24">
        <sdCards>
          <a-row class="buttons" justify="center" :gutter="[8, 8]">
            <sdButton class="btn-add_new" size="large" type="success" @click="goToAttendance">
              <sdFeatherIcons type="check-square" size="14" /><span>เช็คชื่อ</span>
            </sdButton>
            <sdButton class="btn-add_new" size="large" type="success" @click="goToEvaluation">
              <sdFeatherIcons type="file-text" size="14" /><span>บันทึกพัฒนาการ</span>
            </sdButton>
            <sdButton class="btn-add_new" size="large" type="success" @click="goToBodyMass">
              <sdFeatherIcons type="smile" size="14" /><span>บันทึกสัดส่วนร่างกาย</span>
            </sdButton></a-row
          >
        </sdCards>
      </a-col>
      <a-col :md="24">
        <a-spin :spinning="loading">
          <UserListTable :students="students" :schoolYearId="schoolYearId" :classroomId="classroomId" />
        </a-spin>
      </a-col>
      <a-col :md="24">
        <TrafficChannel :schoolYearId="schoolYearId" :classroomId="classroomId" :classroom="classroomInfo"
      /></a-col>
      <a-col :md="24">
        <a-spin :spinning="loading">
          <BodymassClassroomTable :schoolYearId="schoolYearId" :classroomId="classroomId" />
        </a-spin>
      </a-col>
    </a-row>
  </Main>
</template>

<style scoped>
.buttons .ant-btn {
  margin: 4px;
}
</style>

<script>
import UserListTable from './overview/UserTable';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Main, CardToolbox } from '../../styled';
import { getStudentAgeString, getStudentAge, getGradeLevelTitle } from '@/components/utilities/utilities';
import BodymassClassroomTable from './overview/BodymassClassroomTable';

import { defineAsyncComponent } from 'vue';
const TrafficChannel = defineAsyncComponent(() => import('./overview/performance/TrafficChannel'));

const UserList = {
  name: 'UserList',
  components: { Main, CardToolbox, UserListTable, TrafficChannel, BodymassClassroomTable },
  props: {
    schoolYearId: Number,
    classroomId: Number,
  },
  async setup(props) {
    const { getters, state, dispatch } = useStore();

    const classroomId = props.classroomId;
    const schoolYearId = props.schoolYearId;

    const schoolYears = computed(() => state.schoolYear.data);
    const schoolYear = computed(() => schoolYears.value.find(schoolYear => schoolYear.id + '' === props.schoolYearId));
    const classrooms = computed(() => state.classroom.classrooms);
    const classroomInfo = computed(() => getters.getClassroomById(props.classroomId));
    const students = computed(() => getters.getStudentsByClassroom(props.classroomId));
    const loading = computed(() => state.classroom.loading);
    const router = useRouter();

    dispatch('selectSchoolYear', schoolYear);
    if (!classroomInfo.value) {
      const classrooms = await dispatch('getAllClassrooms', { schoolYearId });
      dispatch('updateStudentTable', classrooms);
    }

    const gradeLevel = computed(() => {
      if (!classroomInfo.value) return null;
      return classroomInfo.value.gradeLevel;
    });

    const routes = computed(() => {
      return [
        {
          path: '/',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: '/classroom',
          breadcrumbName: 'ห้องเรียนทั้งหมด',
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classrooms/${props.classroomId}`,
          breadcrumbName: classroomInfo.value.title,
        },
      ];
    });

    const path = `/schoolYears/${schoolYearId}/classrooms/${classroomId}/students/create`;

    const goToBodyMass = () => {
      router.push({
        name: 'classroom-bodymass',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToEvaluation = () => {
      router.push({
        name: 'classroom-evaluation',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToAttendance = () => {
      router.push({
        name: 'classroom-attendance',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToOpinion = () => {
      router.push({
        name: 'classroom-opinion',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToGradeConfirmation = () => {
      router.push({
        name: 'classroom-grade-confirmation',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToImportStudents = () => {
      router.push({
        name: 'import-student',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    const goToNewStudent = () => {
      router.push({
        name: 'create-student',
        props: {
          schoolYearId: schoolYearId,
          classroomId: classroomId,
        },
      });
    };

    return {
      classrooms,
      classroomId,
      path,
      loading,
      students,
      schoolYear,
      getStudentAgeString,
      getStudentAge,
      gradeLevel,
      getGradeLevelTitle,
      classroomInfo,
      BodymassClassroomTable,
      goToBodyMass,
      goToEvaluation,
      goToAttendance,
      goToOpinion,
      goToGradeConfirmation,
      goToNewStudent,
      goToImportStudents,
      routes,
    };
  },
};

export default UserList;
</script>
