<template>
  <sdCards title="รายชื่อนักเรียน">
    <template #button>
      <a-space>
        <sdButton class="btn-add_new" transparented size="default" type="primary" @click="goToNewStudent">
          <sdFeatherIcons type="user-plus" size="14" /><span>เพิ่มนักเรียน</span>
        </sdButton>
        <sdButton class="btn-add_new" transparented size="default" type="primary" @click="goToImportStudents">
          <sdFeatherIcons type="file-plus" size="14" /><span>นำเข้าด้วยไฟล์</span>
        </sdButton>
      </a-space>
    </template>
    <UserTableStyleWrapper>
      <TableWrapper>
        <a-table
          class="np-row"
          :rowSelection="rowSelection"
          :dataSource="usersTableData"
          :columns="usersTableColumns"
          :pagination="{
            defaultPageSize: 20,
            total: usersTableData.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }"
        />
      </TableWrapper>
    </UserTableStyleWrapper>
  </sdCards>
</template>
<script>
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../../styled';
import { computed } from 'vue';
import {
  dateString,
  genderPrifix,
  genderThai,
  getStudentAgeString,
  getStudentAge,
  matchAgeRange,
  getAgeString,
} from '@/components/utilities/utilities';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const usersTableColumns = [
  {
    title: 'เลขที่',
    dataIndex: 'studentNo',
    key: 'studentNo',
  },
  {
    title: 'คำนำหน้า',
    dataIndex: 'prefix',
    key: 'prefix',
  },
  {
    title: 'ชื่อ-นามสกุล',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'รหัสประจำตัวนักเรียน',
    dataIndex: 'studentCode',
    key: 'studentCode',
  },
  {
    title: 'เพศ',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'วันเกิด',
    dataIndex: 'dateOfBirth',
    key: 'dateOfBirth',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: '120px',
  },
];
const UserListTable = {
  name: 'UserListTable',
  components: { UserTableStyleWrapper, TableWrapper },
  props: {
    students: { type: Array, default: [] },
    schoolYearId: Number,
    classroomId: Number,
  },
  setup(props) {
    const { getters } = useStore();
    const router = useRouter();
    const students = computed(() => props.students);
    const classroomId = props.classroomId;
    const classroom = computed(() => getters.getClassroomById(props.classroomId));
    const gradeLevel = computed(() => classroom.value.gradeLevel);
    const schoolYearId = props.schoolYearId;

    const usersTableData = computed(() =>
      students.value.map(student => {
        const { id, firstname, lastname, nickname, studentNo, studentCode, gender, dateOfBirth } = student;

        return {
          key: id,
          studentNo: studentNo,
          prefix: genderPrifix(gender),
          name: (
            <div class="user-info">
              <figcaption>
                <sdHeading class="user-name" as="h1">
                  {firstname} {lastname}
                </sdHeading>
                <span class="user-designation">{nickname}</span>
              </figcaption>
            </div>
          ),
          studentCode: studentCode,
          gender: genderThai(gender),
          dateOfBirth: (
            <div class="user-info">
              <figcaption>
                <sdHeading class="user-name" as="h6">
                  {dateString(dateOfBirth)}
                </sdHeading>
                <div style={`color: ${!matchAgeRange(dateOfBirth, gradeLevel.value) ? 'orange' : 'black'}`}>
                  {!matchAgeRange(dateOfBirth, gradeLevel.value)
                    ? `${getAgeString(dateOfBirth)} (ไม่ตรง)`
                    : getAgeString(dateOfBirth)}
                </div>
              </figcaption>
            </div>
          ),
          status: <span class={`status-text ${status}`}>{status}</span>,
          action: (
            <div class="table-actions">
              <router-link to={`/print/student/${id}`}>
                <sdButton class="btn-icon" type="default" shape="circle">
                  <sdFeatherIcons type="printer" size="10" />
                </sdButton>
              </router-link>
              <router-link to={`/schoolYears/${schoolYearId}/classrooms/${classroomId}/students/${id}`}>
                <sdButton class="btn-icon" type="default" shape="circle">
                  <sdFeatherIcons type="edit" size="10" />
                </sdButton>
              </router-link>
            </div>
          ),
        };
      }),
    );

    const rowSelection = {
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const goToImportStudents = () => {
      router.push({
        name: 'import-student',
        props: {
          schoolYearId: classroomId,
          classroomId: schoolYearId,
        },
      });
    };

    const goToNewStudent = () => {
      router.push({
        name: 'create-student-info',
        props: {
          schoolYearId: classroomId,
          classroomId: schoolYearId,
        },
      });
    };

    return {
      usersTableColumns,
      usersTableData,
      rowSelection,
      dateString,
      getStudentAgeString,
      getStudentAge,
      goToImportStudents,
      goToNewStudent,
    };
  },
};

export default UserListTable;
</script>
